export default class CompletionTranscriptRecord {

  constructor(data) {

      const template = {
        id: 0,
        completionRecordId: "",
        memberId: "",
        itemId: null,
        isContainer: null,
        present: null,
        notes: ""
      };

      // TODO: add validation for mandatory items
      if (data) {
        this.data = data;
        this.data.notes = this.data.notes ? this.data.notes : "";
      } else {
        this.data = template;
      }

  }


  toJson = () => {
    return JSON.stringify(this.data);
  }

  getId = () => {
      return this.data.id;
  }

  setId = (value) => {
    this.data.id = value;
  }

  getCompletionRecordId = () => {
    return this.data.completionRecordId;
  }

  setCompletionRecordId = (value) => {
    this.data.completionRecordId = value;
  }

  getMemberId = () => {
    return this.data.memberId;
  }

  setMemberId = (value) => {
    this.data.memberId = value;
  }

  getItemId = () => {
    return this.data.itemId;
  }

  setItemId = (value) => {
    this.data.itemId = value;
  }

  isContainer = () => {
    return this.data.isContainer;
  }

  setIsContainer = (value) => {
    if (value === true || value === 1 || value === "YES") {
      value = 1;
    }

    if (value === false || value === 0 || value === "NO") {
      value = 0;
    }

    this.data.isContainer = value;
  }

  getPresent = () => {
    return this.data.present;
  }

  setPresent = (value) => {
    if (value === true || value === 1 || value === "YES") {
      value = "YES";
    }

    if (value === false || value === 0 || value === "NO") {
      value = "NO";
    }

    if (value === 3 || value === "ISSUE") {
      value = "ISSUE";
    }

    this.data.present = value;
  } 

  getNotes = () => {
    return this.data.notes;
  }

  setNotes = (value) => {
    this.data.notes = value;
  }
}