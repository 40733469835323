import React from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { TextField, Button, Grid } from '@material-ui/core';
import ServiceAPI from '../../service/ServiceAPI';
import Constants from '../../utils/Constants';
import useAuthentication from '../../hooks/useAuthentication';


const Login = function(props) {
  const navigate = useNavigate();
  const [isAuthenticated, login, logout] = useAuthentication();
  
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoginInvalid, setIsLoginInvalid] = React.useState(false);

  /**
   * 
   * @param {*} e 
   */
  const handleOnChangeEmail = (e) => {
    setUserName(e.target.value);
  }

  /**
   * 
   * @param {*} e 
   */
  const handleOnChangePassword = (e) => {
    setPassword(e.target.value);
  }

  /**
   * 
   * @param {*} e 
   */
  const handleOnClickLogin = (e) => {
    e.preventDefault();

    ServiceAPI.Authorise().authorise(userName, password).then((data) => {
      if (data.error) {
        setIsLoginInvalid(true); 
        logout();
        return;
      }
      
      login(data.jwt);
      window.location.href = window.location.origin + Constants.RoutePath.Inventory.List;
    });

    return false;
  }

console.log("Render: ", isAuthenticated);
  return (
    <div className="view">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <TextField
            error={ isLoginInvalid }
            helperText="Please enter your email"
            id="txtEmail"
            label="Email"
            variant="outlined"
            onChange={handleOnChangeEmail}
            value={userName}
          />
        </Grid>

        <Grid item>
          <TextField
            error={ isLoginInvalid }
            helperText="Please enter your password"
            id="txtPassword"
            label="Password"
            variant="outlined"
            onChange={handleOnChangePassword}
            value={password}
            type="password"
          />
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" onClick={handleOnClickLogin}>
            Login
          </Button>
        </Grid>
        
        { !isLoginInvalid ? null : (
          <Grid item>
            Your login details were incorrect.
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default Login;