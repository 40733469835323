import React, { useState } from 'react';

import ServiceAPI from '../../../service/ServiceAPI';
import { getLongDateStr } from '../../../utils/DateUtils';
import CompletionRecord from '../../../model/CompletionRecord';

import { Box, Button } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const CompletionRecordSelection = function(props) {
  console.assert(props.workflowViews);
  console.assert(props.setCurrentView);
  console.assert(props.existingCompletionRecords);
  console.assert(props.inventoryId);
  console.assert(props.handleSelectedCompletionRecord);

  
  const [showNewCompletionRecordEntry, setShowNewCompletionRecordEntry] = useState(false);
  const [description, setDescription] = useState(getLongDateStr());

  /**
   * 
   */
  const handleClick_newCompletionRecord = () => {
    setShowNewCompletionRecordEntry(true);
  }

  /**
   * 
   */
  const handleClick_selectExistingCompletionRecord = (completionRecordId) => {
    let selectedRecord = props.existingCompletionRecords.filter( record => record.getId() == completionRecordId )[0];

    if ( !isRecordStarted(selectedRecord) ) {
      selectedRecord.setStarted(new Date().toISOString());      
      ServiceAPI.CompletionRecord().updateExistingCompletionRecord(selectedRecord);
    }

    ServiceAPI.CompletionRecord().getInventoryTranscriptListing(completionRecordId).then((data) => {
      selectedRecord.setTranscripts(data);
      props.handleSelectedCompletionRecord(selectedRecord);
      props.setCurrentView(props.workflowViews.LockerSelectionView);
    });
    
  }

  /**
   * 
   */
  const handleClick_createCompletionRecord = () => {
    setShowNewCompletionRecordEntry(false);

    let cr = new CompletionRecord();
    cr.setInventoryId(props.inventoryId);
    cr.setDescription(description);
    
    ServiceAPI.CompletionRecord().createNewCompletionRecord(cr);
  }
  
  /**
   * 
   * @param {*} e 
   */
  const handleChange_description = (e) => {
    setDescription(e.target.value);
  }

  /**
   * Checks the state of the record to infer if the 
   * inventory record has been STARTED
   * @param {*} record 
   */
  const isRecordStarted = function(record) {
    return (record.getStarted() !== null);
  }

  /**
   * Checks the state of the record to infer if the 
   * inventory record has been STARTED
   * @param {*} record 
   */
  const isRecordComplete = function(record) {
    return (record.getStarted() !== null && record.getCompleted() !== null)
  }

  /**
   * Main render logic
   * @returns 
   */
  const render = function() {
    let jsx = null;

    if (showNewCompletionRecordEntry == false) {
      jsx = renderExistingCompletionRecordsForSelection();
    } else {
      jsx = renderNewCompletionRecordEntry();
    }

    return jsx;
  }

  /**
   * Render an indivdual record selection card
   * @param {*} record 
   * @returns 
   */
  const renderExistingCompletionRecordCard = function(record) {
    let actionText = !isRecordStarted(record) ? 'Start' : (isRecordComplete(record) ? 'Complete' : 'Continue');

    return (
      <Card style={{margin: '2em', backgroundColor: 'whitesmoke'}} key={ record.getId() }>
        <CardActionArea>
          <CardContent onClick={ () => { handleClick_selectExistingCompletionRecord(record.getId()) } }>
            <Box alignItems={'left'}>
              <Typography gutterBottom variant="h5" component="h2">
                { record.getDescription() }
              </Typography>
              <Typography gutterBottom>
                { !isRecordStarted(record) ? 'Not started' : new Date(record.getStarted()).toLocaleDateString() }
                { ' / ' }
                { isRecordComplete(record) ? new Date(record.getCompleted()).toLocaleDateString() : 'Incomplete' }
              </Typography>
            </Box>
            <Box alignItems={'right'}>
              <Button variant="contained" color="primary" size="medium">
                { actionText }
              </Button>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    );
   }

  /**
   * Render a list of existing completion records to choose from.
   */
  const renderExistingCompletionRecordsForSelection = function() {
    let jsx = (
      <Box marginLeft={1} marginRight={1} display={'flex'} flexDirection={'column'} height={'90%'}>
        <Box>
          <h2>Select Completion Record</h2>
        </Box>

        <Box flex={1} overflow={'auto'}>
          { 
            (props.existingCompletionRecords.length > 0) 
              ? props.existingCompletionRecords.map(record => renderExistingCompletionRecordCard(record))
              : <h3>Create a new Completion Record to begin</h3>
          }
        </Box>

        <Box>
          <Button variant="contained" color="default" size="large" onClick={handleClick_newCompletionRecord}>
            New Completion Record
          </Button>
        </Box>
      </Box>
    )

    return jsx;
  }

  /**
   * Render the field for creating a new completion record
   */
  const renderNewCompletionRecordEntry = function() {
    let jsx = (
      <div>
        <h2>New Completion Record</h2>
        
        <TextField id="outlined-basic" label="Description" variant="outlined" value={description} onChange={handleChange_description} />
        
        <Box marginTop={'1em'}>
          <Button variant="contained" color="default" size="large" style={{margin: '.5em'}} onClick={() => setShowNewCompletionRecordEntry(false) }>
            Cancel
          </Button>
          <Button variant="contained" color="primary" size="large" style={{margin: '.5em'}} onClick={handleClick_createCompletionRecord}>
            Create
          </Button>
        </Box>
      </div>
    );
    
    return jsx;
  }

  return render();
}

export default CompletionRecordSelection;