import Constants from '../utils/Constants';
import ServiceBase from './ServiceBase';
import CompletionRecord from '../model/CompletionRecord';
import CompletionTranscriptRecord from '../model/CompletionTranscriptRecord';

const RECORD_URL = 'record/';
const TRANSCRIPT_URL = 'transcript/';

export default class CompletionRecordService extends ServiceBase {

  /**
   * 
   * @param {*} id 
   * @returns 
   */  
  getCompletionRecordListing = (id) => {
    let url = this.getServiceUrl(RECORD_URL + "?" + Constants.Http.Param.Id + "=" + id );

    let packet = this.buildRequestPacket(Constants.Http.Method.GET);

    return fetch(url, packet).then(this.convertResponseToJson).then(json => {
      let convertedRecords = [];
      json.map(record => convertedRecords.push(new CompletionRecord(record)) );

      return convertedRecords;
    });
  }
  
  /**
   * 
   * @param {*} completionRecord 
   * @returns 
   */
  createNewCompletionRecord = (completionRecord) => {
    let url = this.getServiceUrl(RECORD_URL);
    let packet = this.buildRequestPacket(Constants.Http.Method.POST, completionRecord);

    return fetch(url, packet).then(this.convertResponseToJson).then(json => {
      return new CompletionRecord(json);
    });
  }

  /**
   * 
   * @param {*} completionRecord 
   */
  updateExistingCompletionRecord = (completionRecord) => {
    let url = this.getServiceUrl(RECORD_URL);
    let packet = this.buildRequestPacket(Constants.Http.Method.PUT, completionRecord);

    return fetch(url, packet).then(this.convertResponseToJson).then(json => {
      return new CompletionRecord(json);
    });
  }

  /**
   * Creates a Completion Transcript Record service call.
   * @param {*} transcriptRecord 
   */
  createInventoryCompletionTranscriptRecord = (completionTranscriptRecord) => {
    let url = this.getServiceUrl(RECORD_URL + TRANSCRIPT_URL);
    let packet = this.buildRequestPacket(Constants.Http.Method.POST, completionTranscriptRecord);

    return fetch(url, packet).then(this.convertResponseToJson).then(json => {
      return json;
    });
  }

  /**
   * Creates a Completion Transcript Record Generate Report & Email service call.
   * @param {*} transcriptRecord 
   */
  createInventoryCompletionTranscriptRecordReportAndEmail = (completionRecord) => {
    let url = this.getServiceUrl(RECORD_URL + "report.php");
    
    let payload = {
      inventoryId: completionRecord.getInventoryId() , 
      completionRecord: completionRecord.getId()
    };
    
    let packet = this.buildRequestPacket(Constants.Http.Method.POST, payload);
    
    return fetch(url, packet).then(this.convertResponseToJson).then(json => {
      return json;
    });
  }

  /**
   * Returns the complete listing of Comletion Record Transcripts for the provide Completion Record Id
   * @param {*} id 
   */
  getInventoryTranscriptListing = (id) => {
    let url = this.getServiceUrl(RECORD_URL + TRANSCRIPT_URL + "?" + Constants.Http.Param.Id + "=" + id );

    let packet = this.buildRequestPacket(Constants.Http.Method.GET);

    return fetch(url, packet).then(this.convertResponseToJson).then(json => {
      let convertedRecords = [];
      json.map(record => convertedRecords.push(new CompletionTranscriptRecord(record)) );

      return convertedRecords;
    });
  }
}